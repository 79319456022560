// custom typefaces
import "typeface-prata"
import "typeface-inter"
// import "typeface-montserrat"
// import "typeface-merriweather"

import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

console.log("ding")
